.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
  padding: 20px;
  max-width: 60%;
  
}
.landing-image {
  pointer-events: none;
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 0s linear;
  }
}

.App-header {
  background-color: #dcdbd9;
  color: #3b424c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0px + 0vmin);
  display: flex;
  text-decoration: ;
  font-family: Georgia;

  position: -webkit-sticky;
  position: sticky;
  top: 0;
  //background-image: linear-gradient(319deg, #faff00 0%, #ff1000 37%, #ff6a00 100%);
  padding: 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  margin: 0px;
  overflow-x: hidden;
  filter: drop-shadow(0px 1px 1px #b5b5b5);
  letter-spacing: 5px;
}

.App-body{
  background-color: #fff;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  display: flex;
}
.App-footer{
  background-color: #dcdbd9;
  color: #3b424c;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
